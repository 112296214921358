import React, { Suspense, lazy } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Loading from "./components/Loading";
import Footer from "./components/Footer";
import ScrollToTop from "./lib/ScrollToTop";

const Home = lazy(() => import(/* webpackChunkName: "Home" */ "./pages/Home"));
const About = lazy(() => import(/* webpackChunkName: "About" */ "./pages/About"));
const Contact = lazy(() => import(/* webpackChunkName: "Contact" */ "./pages/Contact"));
const Careers = lazy(() => import(/* webpackChunkName: "Careers" */ "./pages/Careers"));
const JobDetails = lazy(() => import(/* webpackChunkName: "JobDetails" */ "./pages/JobDetails"));
const Privacy = lazy(() => import(/* webpackChunkName: "Privacy" */ "./pages/Privacy"));
const Security = lazy(() => import(/* webpackChunkName: "Security" */ "./pages/Security"));
const Terms = lazy(() => import(/* webpackChunkName: "Terms" */ "./pages/Terms"));

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="content">
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route exact path="/about">
                            <About />
                        </Route>
                        <Route exact path="/contact">
                            <Contact />
                        </Route>
                        <Route exact path="/careers">
                            <Careers />
                        </Route>
                        <Route exact path="/careers/:id">
                            <JobDetails />
                        </Route>
                        <Route exact path="/privacy">
                            <Privacy />
                        </Route>
                        <Route exact path="/security">
                            <Security />
                        </Route>
                        <Route exact path="/terms">
                            <Terms />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </Suspense>
            </div>
            <Footer />
        </Router>
    );
}

export default App;
